
import { defineComponent } from 'vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import TmSpinner from '@/components/shared/TmSpinner.vue'
import SkeletonPerson from '@/components/shared/skeleton/SkeletonPerson.vue'
import TmSkeleton from '@/components/shared/TmSkeleton.vue'

export default defineComponent({
  name: 'Progress',
  components: { TmSkeleton, SkeletonPerson, TmProgress, TmSpinner },
})
